<template>
  <!--  用户管理组件-->
  <div class="in-workbench">
    <el-row :gutter=20>
      <el-col :span=4>
        <el-button type="primary" class="btn-full" @click="newUser">新增用户</el-button>
      </el-col>
    </el-row>
    <!-- 顶部操作栏结束，表格开始 -->
    <el-row :gutter=20>
      <el-col :span=24>
        <el-table :data="allUser" border class="tableInWorkbench" height="calc(100vh - 180px)">
          <el-table-column prop="username" :width=120 label="用户名"></el-table-column>
          <el-table-column prop="realName" :min-width=120 label="姓名"></el-table-column>
          <el-table-column prop="mobile" :width=120 label="手机号码"></el-table-column>
          <el-table-column prop="allowCompanys" :min-width=150 label="所属公司"></el-table-column>
          <el-table-column prop="appellation" :min-width=150 label="职务"></el-table-column>
          <el-table-column prop="remark" :min-width=150 label="备注" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column v-if="enableEdit" :resizable="false" align="center" :width=220 label="操作">
            <template slot-scope="scope">
              <el-link @click="editUser(scope.row)">编辑</el-link>&nbsp;
              <el-link v-if="Permission" @click="authorizeUser(scope.row)">功能授权</el-link>&nbsp;
              <el-link v-if="Per" @click="authorizeMachine(scope.row)">区域授权</el-link>&nbsp;
              <el-link @click="del(scope.row)">删除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 表格结束，编辑用户信息弹出框开始 -->
    <el-dialog title="编辑人员信息" :visible.sync="editUserVisible" :append-to-body="true" width="50%"
               top="calc((100vh - 600px) / 2)" :center="true" :close-on-click-modal=false>
      <el-form>
        <el-row>
          <el-col :xs=24 :sm=12>
            <el-form-item label="账号" label-width="80px">
              <el-input  v-model="editingUser.username"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="昵称" label-width="80px">
              <el-input  v-model="editingUser.nickname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="姓名" label-width="80px">
              <el-input v-model="editingUser.realName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="证件号码" label-width="80px">
              <el-input v-model="editingUser.idNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="电子邮箱" label-width="80px">
              <el-input v-model="editingUser.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="电话号码" label-width="80px">
              <el-input v-model="editingUser.mobile"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="公司" label-width="80px">
              <el-select v-model="editingUser.allowCompanys" default-first-option placeholder="请选择公司">
                <el-option key="城南热电" label="城南热电" value="城南热电"></el-option>
                <el-option key="抚顺新北方" label="抚顺新北方" value="抚顺新北方"></el-option>
                <el-option key="新北方高湾" label="新北方高湾" value="新北方高湾"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="职务" label-width="80px">
              <el-select v-model="editingDuty" class="btn-full" multiple filterable allow-create default-first-option
                         placeholder="请选择职务">
                <el-option v-for="item in duties" :key="item" :value="item" :label="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="新的密码" label-width="80px">
              <el-input v-model="testpassword" type="password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="核对密码" label-width="80px">
              <el-input v-model="fixnewpassword" type="password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <p style="color: red;margin-left: 25%">新的密码不填视为不修改密码</p>
          </el-col>
          <!--          这里重新编辑性别-->
          <el-col :xs=24 :sm=12>
            <el-form-item label="性别" prop="resource">
              <el-radio-group v-model="editingUser.gender">
                <el-radio label="男"></el-radio>
                <el-radio label="女"></el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span=24>
            <el-form-item label="备注" label-width="80px">
              <el-input type="textarea" :rows="4" v-model="editingUser.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="finishEdit">保存人员信息</el-button>
        <el-button type="danger" @click="cancelEdit">取消编辑</el-button>
      </span>
    </el-dialog>
    <!-- 编辑用户信息弹出框结束，授权弹出框开始 -->
    <el-dialog title="为人员授权" :visible.sync="authorizeUserVisible" :append-to-body="true" width="50%"
               top="calc((100vh - 540px) / 2)" :center="true" :close-on-click-modal=false>
      <el-transfer
        filterable
        :titles="['所有分区', '已选择区域']"
        :button-texts="['到左边', '到右边']"
        filter-placeholder="请输入区域名"
        v-model="selectedMachineId"
        :data="allMachine">
      </el-transfer>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="finishMachineAuthorize">保存权限</el-button>
        <el-button type="danger" @click="cancelAuthorize">取消授权</el-button>
      </span>
    </el-dialog>
    <el-dialog title="新增用户" :visible.sync="UpDateUser" :append-to-body="true" width="50%"
               top="calc((100vh - 600px) / 2)" :center="true" :close-on-click-modal=false>
      <el-form>
        <el-row>
          <el-col :xs=24 :sm=12>
            <el-form-item label="姓名" label-width="80px">
              <el-input v-model="UpdateUserinfo.realName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="性别" prop="resource" style="margin-left: 4%">
              <el-radio-group v-model="UpdateUserinfo.gender">
                <el-radio label="男"></el-radio>
                <el-radio label="女"></el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="用户名" label-width="80px">
              <el-input v-model="UpdateUserinfo.username" @blur="makesureonly"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="电子邮箱" label-width="80px">
              <el-input v-model="UpdateUserinfo.email"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="密码" label-width="80px">
              <el-input v-model="UpdateUserinfo.password" type="password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="核对密码" label-width="80px">
              <el-input v-model="UpdateUserinfo.newpassword" type="password" @blur="check"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs=24 :sm=12>
            <el-form-item label="电话号码" label-width="80px">
              <el-input v-model="UpdateUserinfo.mobile"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="证件号码" label-width="80px">
              <el-input v-model="UpdateUserinfo.idNum"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs=24 :sm=12>
            <el-form-item label="公司" label-width="80px">
              <company-selector class="btn-full" v-model="UpdateUserinfo.allowCompanys"></company-selector>
            </el-form-item>
          </el-col>

          <el-col :xs=24 :sm=12>
            <el-form-item label="职务" label-width="80px">
              <el-select v-model="editingDuty" class="btn-full" multiple filterable allow-create default-first-option
                         placeholder="请选择职务">
                <el-option v-for="item in duties" :key="item" :value="item" :label="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span=24>
            <el-form-item label="备注" label-width="80px">
              <el-input type="textarea" :rows="4" v-model="UpdateUserinfo.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureUpdate">确定新增</el-button>
        <el-button type="danger" @click="back">取消新增</el-button>
      </span>
    </el-dialog>
<!--    功能授权弹出框-->
    <el-dialog title="为人员授权" :visible.sync="authorizatiodialog" :append-to-body="true" width="50%"
               top="calc((100vh - 540px) / 2)" :center="true" :close-on-click-modal=false>
      <template>
        <el-checkbox-group
          v-model="audituser">
          <el-checkbox v-for="city in allaudipree" :label="city" :key="city" style="width: 16%">{{city.permissionName}}</el-checkbox>
        </el-checkbox-group>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="finishauthorizeUser">保存权限</el-button>
        <el-button type="danger" @click="hiddauthorizeUser">取消授权</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import clientUtils from '../../js/clientUtils'
  import serverMessages from '../../js/serverMessages'
  import systemNames from '../../js/systemNames'
  import enums from '../../js/enums'
  import api from "../../js/api";
  import permissions from "../../js/permissions";
  import CompanySelector from "../controls/CompanySelector";
  // import {todayEnd} from "../../js/globalConfiguration";

  export default {
    name: 'StaffManagement',
    components: {CompanySelector},
    data: function () {
      return {
        allMachine: [],
        // data: generateData(),
        selectedMachineId: [],
        Permission:false,
        Per:false,
        allinfo: [],
        pagePermissionKey: permissions.STAFF_MANAGEMENT,
        enableEdit: true, // clientUtils.hasPermission(permissions.EDIT_STAFF),
        userInfo: {},
        // 用户信息
        allUser: [],
        //更新用户
        UpdateUserinfo: {},
        // 用户编辑
        editUserTitle: '新增用户',
        // Users:null,
        editUserVisible: false,
        authorizatiodialog:false,
        UpDateUser: false,
        flig: true,
        //弹出密码不一致修改标记
        PwdFlig: true,
        editingUser: {},
        // 用户授权
        authorizeUserVisible: false,
        editingDuty: [],
        duties: [],
        basicPermission: [],
        statisticsPermission: [],
        systemPermission: [],
        editingPermissions: [],
        audituser:[],//选中权限权限
        allaudipree:[],//所有权限
        testpassword: '',
        fixnewpassword: '',
        // 区域授权
        authorizeStationVisible: false
      }
    },
    filters: {
      toDate: function (jsonDatetime) {
        return clientUtils.formatDatetime(jsonDatetime, enums.datetimeFormat.FULL_DATE_CHS_NO_WEEK)
      }
    },
    methods: {
      newUser: function () {
        this.UpDateUser = true;
        this.UpdateUserinfo = {}
      },
      check: function () {
        if (this.UpdateUserinfo.password == undefined || this.UpdateUserinfo.password == "" || this.UpdateUserinfo.newpassword == undefined || this.UpdateUserinfo.newpassword == "") {
          clientUtils.popErrorMessage("密码是必填选项哦")
        } else if (this.UpdateUserinfo.password === this.UpdateUserinfo.newpassword) {
          this.PwdFlig = true
        } else {
          this.PwdFlig = false;
          clientUtils.popErrorMessage("两次输入的密码不一致")
        }
      },
      //确定新增用户方法
      sureUpdate: function () {
        this.UpdateUserinfo.nickname = this.UpdateUserinfo.realName;
        let UpatejJson = {};
        const temp = JSON.parse(JSON.stringify(this.UpdateUserinfo));
        UpatejJson.username = temp.username;
        UpatejJson.password = clientUtils.encryptMd5(temp.password);
        UpatejJson.email = temp.email;
        UpatejJson.mobile = temp.mobile;
        UpatejJson.idNum = temp.idNum;
        UpatejJson.nickname = temp.nickname;
        UpatejJson.realName = temp.realName;
        UpatejJson.gender = temp.gender;
        UpatejJson.allowCompanys = temp.allowCompanys;
        UpatejJson.appellation = clientUtils.arrayToCommaString(this.editingDuty);
        UpatejJson.remark = temp.remark;
        //重组对象发送给后端json
        console.log('id' + UpatejJson.idNum);
        let str = JSON.stringify(UpatejJson);
        console.log(str);
        if (UpatejJson.username === undefined) {
          clientUtils.popErrorMessage("用户名不能为空")
        } else if (UpatejJson.realName === undefined) {
          clientUtils.popErrorMessage("请填写姓名")
        } else if (UpatejJson.allowCompanys === undefined) {
          clientUtils.popErrorMessage("请选择所在公司")
        } else if (this.UpdateUserinfo.password == undefined) {
          clientUtils.popErrorMessage("密码不能为空")
        } else if (this.UpdateUserinfo.password == undefined || this.UpdateUserinfo.password == "" || this.UpdateUserinfo.newpassword == undefined || this.UpdateUserinfo.newpassword == "") {
          clientUtils.popErrorMessage("密码是必填选项哦")
        } else if (this.flig && this.PwdFlig) {
          const that = this;
          clientUtils.post('/system/user/add', str, function (res) {
            if (res.data.msg != null && res.data.msg === serverMessages.SUCCESS) {
              // 编辑用户信息成功
              that.loadAllUser();
              that.back();
            } else {
              clientUtils.unknownErrorAlert(that, res.data.msg)
            }
          })
        } else {
          clientUtils.popErrorMessage("请检查用户名和密码")
        }

      },
      editUser: function (user) {
        this.editingUser = JSON.parse(JSON.stringify(user));
        this.editingDuty = clientUtils.commaStringToArray(this.editingUser.appellation);
        // 显示编辑窗口
        this.testpassword = "";
        this.fixnewpassword = "";
        this.editUserVisible = true
      },
      //编辑人员信息提交方法
      finishEdit: function () {
        this.editingUser.appellation = clientUtils.arrayToCommaString(this.editingDuty);
        if (this.testpassword == "") {
          const that = this;
          clientUtils.post('/system/user/modify', this.editingUser, function (res) {
            if (res.data.msg != null && res.data.msg === serverMessages.SUCCESS) {
              // 编辑用户信息成功
              that.loadAllUser();
              that.cancelEdit();
            } else {
              clientUtils.unknownErrorAlert(that, res.data.msg)
            }
          })
        } else if (this.testpassword != this.fixnewpassword) {
          clientUtils.popErrorMessage("请仔细核对密码")
        } else {
          this.editingUser.password = clientUtils.encryptMd5(this.testpassword);
          console.log(JSON.stringify(this.editingUser));
          const that = this;
          clientUtils.post('/system/user/modify', this.editingUser, function (res) {
            if (res.data.msg != null && res.data.msg === serverMessages.SUCCESS) {
              // 编辑用户信息成功
              that.loadAllUser();
              that.cancelEdit();
            } else {
              clientUtils.unknownErrorAlert(that, res.data.msg)
            }
          })
        }
      },
      //弹出框隐藏方法
      cancelEdit: function () {
        this.editUserVisible = false
      },
      //弹出框隐藏方法
      back: function () {
        this.UpDateUser = false
      },
      //功能授权方法
      authorizeUser: function (user) {
        this.editingUser = JSON.parse(JSON.stringify(user));
        console.log(JSON.stringify(this.editingUser));
        this.audituser=clientUtils.commaStringToArray(this.editingUser.permission);
        this.authorizatiodialog =true;
        const that = this;
        clientUtils.get('/system/permission/findAll',function (res) {
          if (res.data != null){
            that.allaudipree = res.data.data;
            var string = that.editingUser.permission;
            var arr =[];
            arr = string.split("|");
            arr.shift();
            arr.pop();
            console.log(arr);
         if (arr.length>1){
           console.log("执行");
           for(let [key,val] of that.allaudipree.entries()){
             for (let i = 0; i < arr.length; i++) {
               if (arr[i]==val.permissionKey){
                 console.log(JSON.stringify(arr));
                 console.log(key);
                 that.audituser.push(that.allaudipree[key])
               }
             }
           }
         }
          }
        })


      },
      //取消功能授权的方法
      hiddauthorizeUser:function () {
        this.authorizatiodialog =false
      },
      //保存功能授权的功能
      finishauthorizeUser:function () {
        const that = this;
        let newPermission = '|';
        if (this.audituser.length==0){
           newPermission = '';
        }else {
           newPermission = '|';
          for (let i = 0; i < this.audituser.length; i++) {
            newPermission += this.audituser[i].permissionKey;
            newPermission += '|';
            console.log('拼接后'+newPermission);
            this.editingUser.permission = newPermission
          }
        }
        clientUtils.post(api.MODIFY_USER, this.editingUser, function (res) {
          if (res.data.msg === serverMessages.SUCCESS) {
            clientUtils.popSuccessMessage("授权成功！");
            that.loadAllUser();
            that.hiddauthorizeUser()
          }
        });
      },
      //区域授权方法
      authorizeMachine: function (user) {
        console.log("点击加载数据执行了加载所有用户");
        this.editingUser = JSON.parse(JSON.stringify(user));
        this.selectedMachineId = clientUtils.commaStringToArray(this.editingUser.allowMachines);
        this.authorizeUserVisible = true;
        const that = this;
        that.allMachine = [];
        clientUtils.get('/system/machine/all', function (res) {
          //返回json
          if (res.data) {
            console.log(JSON.stringify(res.data[1].name));
            //便利返回的接送数组
            for (let i = 0; i < res.data.length; i++) {
              that.allMachine.push({
                label: res.data[i].name,
                key: res.data[i].id
              });
              // that.address.push(res.data[i].name)
            }
          }
        })
      },
      finishAuthorize: function () {
        const that = this;
        let newPermission = '|';
        for (let i = 0; i < this.basicPermission.length; i++) {
          if (this.basicPermission[i].version !== '0') {
            newPermission += that.basicPermission[i].permissionKey;
            newPermission += '|'
          }
        }
        console.log(newPermission);
        this.editingUser.permission = newPermission;
        clientUtils.post('/ucm/edit', this.editingUser, function (res) {
          if (res.data.msg != null && res.data.msg === serverMessages.SUCCESS) {
            // 用户授权成功
            that.loadAllUser();
            that.cancelAuthorize();
            if (that.ucm.id === that.editingUser.id) {
              localStorage.setItem(systemNames.localStorageUcm, clientUtils.encryptLocalStorage(that.editingUser));
              that.$emit('ucmChanged')
            }
          } else if (res.data.msg != null && res.data.msg === serverMessages.SELF_REVOKE_FROM_EDIT_STAFF_NOT_ALLOWED) {
            clientUtils.alert('您必须为自己保留“人员管理”权限。如确实需要撤销，请登录其他管理员账号进行操作。')
          } else {
            clientUtils.unknownErrorAlert(res.data.msg)
          }
        })
      },
      //删除用户的方法
      del: function (user) {
        let obj = JSON.parse(JSON.stringify(user));
        const that = this;
        this.$confirm('确定要删除 ' + obj.username + ' 吗？', clientUtils.Tip, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(() => {
          // 确定解除绑定
          let obj = JSON.parse(JSON.stringify(user));
          console.log(obj);
          clientUtils.get('/system/user/delete/' + obj.id, function (res) {
            //返回接送数据后判段是否为空是否成功
            if (res.data.msg != null && res.data.msg === serverMessages.SUCCESS) {
              // 编辑用户信息成功
              that.loadAllUser();
              // that.backing();
            } else {
              // clientUtils.unknownErrorAlert(that, res.data.msg)

            }
          })
        }).catch(() => {
        })
      },
      //判断用户名是否重复的方法
      makesureonly: function () {
        let coveruser = this.UpdateUserinfo.username;
        // console.log(coveruser.length)
        if (coveruser === undefined) {
          clientUtils.popErrorMessage("请认真填写用户名")
        } else {
          //此处判断用户名的长度
          let that = this;
          clientUtils.get('/system/user/has_username/' + coveruser, function (res) {
            if (res.data.msg === serverMessages.EXIST) {
              clientUtils.popErrorMessage("用户名重复");
              that.flig = false
            } else {
              that.flig = true
            }
          });
        }
      },
      finishMachineAuthorize: function () {
        const that = this;
        this.editingUser.allowMachines = clientUtils.arrayToCommaString(this.selectedMachineId);
        clientUtils.post(api.MODIFY_USER, this.editingUser, function (res) {
          if (res.data.msg === serverMessages.SUCCESS) {
            clientUtils.popSuccessMessage("区域授权成功！");
            that.loadAllUser()
          }
        });
        this.authorizeUserVisible = false;
      },
      cancelAuthorize: function () {
        this.authorizeUserVisible = false
      },
      removeUserBind: function (user) {
        const that = this;
        const userTip = user.nickname + ' (' + user.userMobile + ')';
        this.$confirm('确定要解除 ' + userTip + ' 与本店的绑定吗？', clientUtils.Tip, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(() => {
          // 确定解除绑定
          clientUtils.get('/ucm/unbind/' + user.userId, function (res) {
            if (res.data.msg != null && res.data.msg === serverMessages.SUCCESS) {
              // 解除绑定成功
              clientUtils.popSuccessMessage('已成功解除 ' + userTip + ' 的绑定！');
              that.loadAllUser()
            } else if (res.data.msg != null && res.data.msg === serverMessages.DELETE_NOT_ALLOWED) {
              clientUtils.alert(userTip + ' 是本店唯一的管理员，如要移除绑定，必须先为其他用户授予人员管理权限！')
            } else {
              clientUtils.networkErrorAlert()
            }
          })
        }).catch(() => {
        })
      },
      //加载所有用户的方法
      loadAllUser: function () {
        const that = this;
        clientUtils.get(api.GET_ALL_USER, function (res) {
          if (res.data) {
            that.allUser = res.data;
          }
        });
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.$emit('selectMenu', this.$route.path, '用户管理');
        this.userInfo = clientUtils.getLocalUserInfo();
        // 加载全部用户绑定信息
        this.loadAllUser();
        // 加载全部权限信息
      });
      const that = this
      clientUtils.get('/system/user/isUserVip?id='+clientUtils.getLocalUserInfo().id+'&type=S_SM',function (res) {
        that.Permission=res.data
      })
      const thats = this
      clientUtils.get('/system/user/isUserVip?id='+clientUtils.getLocalUserInfo().id+'&type=S_MM',function (res) {
        console.log(res.data)
        thats.Per=res.data
        console.log("fdas"+thats.Permission)
      })
    }
  }
</script>

<style scoped>
  .permissionHeader {
    background-color: #F5F7FA;
    border: 1px solid #DCDFE6;
    text-align: center;
    font-weight: bold;
    padding: 10px 0;
    line-height: 20px;
    font-size: 14px;
  }

  .permissionContent {
    border: 1px solid #DCDFE6;
    border-top: none;
  }

  .permissionContent > div {
    padding: 9px;
    line-height: 22px;
  }
</style>
