<template>
  <el-select v-model="innerValue" default-first-option placeholder="请选择公司" @change="inputHandler">
    <el-option key="城南热电" label="城南热电" value="城南热电"></el-option>
    <el-option key="新北方供热" label="新北方供热" value="新北方供热"></el-option>
    <el-option key="新北方高湾" label="新北方高湾" value="新北方高湾"></el-option>
  </el-select>
</template>

<script>
import permissions from "../../js/permissions";

export default {
  name: "CompanySelector",
  data: function () {
    return {
      pagePermissionKey: permissions.NONE,
      innerValue: ''
    }
  },
  methods: {
    inputHandler: function (val) {
      this.$emit('input', val);
      this.$emit('change', val);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('selectMenu', this.$route.path)
    })
  }
}
</script>

<style scoped>

</style>
